exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-applications-index-js": () => import("./../../../src/pages/applications/index.js" /* webpackChunkName: "component---src-pages-applications-index-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-downloads-js": () => import("./../../../src/pages/downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-markets-aromatic-compounds-js": () => import("./../../../src/pages/markets/aromatic-compounds.js" /* webpackChunkName: "component---src-pages-markets-aromatic-compounds-js" */),
  "component---src-pages-markets-chemical-fine-chemicals-and-agrochemical-js": () => import("./../../../src/pages/markets/chemical-fine-chemicals-and-agrochemical.js" /* webpackChunkName: "component---src-pages-markets-chemical-fine-chemicals-and-agrochemical-js" */),
  "component---src-pages-markets-coating-js": () => import("./../../../src/pages/markets/coating.js" /* webpackChunkName: "component---src-pages-markets-coating-js" */),
  "component---src-pages-markets-index-js": () => import("./../../../src/pages/markets/index.js" /* webpackChunkName: "component---src-pages-markets-index-js" */),
  "component---src-pages-markets-metallurgy-js": () => import("./../../../src/pages/markets/metallurgy.js" /* webpackChunkName: "component---src-pages-markets-metallurgy-js" */),
  "component---src-pages-markets-packaging-js": () => import("./../../../src/pages/markets/packaging.js" /* webpackChunkName: "component---src-pages-markets-packaging-js" */),
  "component---src-pages-markets-pharmaceutical-js": () => import("./../../../src/pages/markets/pharmaceutical.js" /* webpackChunkName: "component---src-pages-markets-pharmaceutical-js" */),
  "component---src-pages-markets-power-generation-js": () => import("./../../../src/pages/markets/power-generation.js" /* webpackChunkName: "component---src-pages-markets-power-generation-js" */),
  "component---src-pages-markets-semiconductors-js": () => import("./../../../src/pages/markets/semiconductors.js" /* webpackChunkName: "component---src-pages-markets-semiconductors-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-products-ananta-js": () => import("./../../../src/pages/products/ananta.js" /* webpackChunkName: "component---src-pages-products-ananta-js" */),
  "component---src-pages-products-arya-js": () => import("./../../../src/pages/products/arya.js" /* webpackChunkName: "component---src-pages-products-arya-js" */),
  "component---src-pages-products-evo-double-stage-rotary-piston-js": () => import("./../../../src/pages/products/evo-double-stage-rotary-piston.js" /* webpackChunkName: "component---src-pages-products-evo-double-stage-rotary-piston-js" */),
  "component---src-pages-products-hvhd-js": () => import("./../../../src/pages/products/hvhd.js" /* webpackChunkName: "component---src-pages-products-hvhd-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

